<template>
    <div>
        <v-card>
            <v-toolbar flat color="transparent">
                <v-toolbar-title>
                    {{ type }} Slot
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn @click="$emit('save',{
                    date: selectedDate,
                    time: selectedTime
                })" icon color="secondary"><v-icon>save</v-icon> </v-btn>
                <v-btn text @click="$emit('close')">X</v-btn>
            </v-toolbar>
            <v-card-text>
                <v-row justify="center">
                    <v-col cols="12" class="text-center">
                        <v-date-picker v-model="selectedDate"></v-date-picker>
                    </v-col>
                    <v-col cols="12" class="text-center">
                        <v-row justify="center">
                            <v-autocomplete style="width: 200px" dense :items="timeOptions" v-model="selectedTime" outlined
                            label="Time" prepend-inner-icon="schedule"></v-autocomplete>
                        </v-row>
                       
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </div>
</template>
<script>
export default {
    props: ['type', 'date'],
    data: () => ({
        loading: false,
        selectedDate: null,
        selectedTime: null,
        timeOptions: []
    }),
    watch: {
        'date': {
            immediate: true,
            handler(val) {
                if (val) {
                    this.selectedDate = new Date(val).toISOString().substring(0, 10)
                    this.selectedTime = new Date(val).toISOString().substring(11, 5)
                }
            }
        },
    },
    created() {
        this.timeOptions = this.getTimeOptions()
    },
    methods: {
        getTimeOptions() {
            let start = new Date()
            start.setHours(0, 0, 0, 0)
            let end = new Date()
            end.setHours(23, 45, 0, 0)

            let timeOptions = []
            while (start <= end) {
                timeOptions.push(start.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }))
                start.setMinutes(start.getMinutes() + 15)
            }
            return timeOptions
        }
    }
}
</script>